// GamePage.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './GamePage.css';

const GamePage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const games = [
    { id: 1, title: "Destroy Drops", genre: 'Simulation', description: 'A fun simulation game with endless possibilities.', gameplayVideo: '/videos/tobi-gameplay.mp4' },
    { id: 2, title: 'Rythm Run', genre: 'Adventure', description: 'Embark on an epic journey in this adventure game.', gameplayVideo: '/videos/epic-gameplay.mp4' },
    { id: 3, title: 'Mad Marbles', genre: 'Racing', description: 'Fast-paced racing action awaits in Racing Madness.', gameplayVideo: '/videos/racing-gameplay.mp4' },
    { id: 4, title: 'Trivia Chaser', genre: 'Trivia', description: 'Answer trivia questions to chase the high score.', gameplayVideo: '/videos/trivia-gameplay.mp4' },
  ];

  const game = games.find((g) => g.id === parseInt(gameId));

  if (!game) {
    return <p>Game not found</p>;
  }

  const handleBack = () => {
    navigate('/');
  };

  const handlePlayClick = () => {
    navigate(`/loading/${gameId}`); // Navigate to LoadingPage with gameId
  };

  return (
    <div className="game-page">
      <div className="gameheader">
        <div className="gameheader-content">
          <button className="back-button" onClick={handleBack}>
            <img src="/images/arrow-left-icon.png" alt="Back" />
          </button>
          <video 
            className="game-video" 
            src={game.gameplayVideo} 
            autoPlay 
            loop 
            muted 
            playsInline
          />
          <div className="game-title-pill">
            <span>{game.title}</span>
          </div>
        </div>
      </div>

      <div className="about-section">
        <p className="about-text">{game.description}</p>
      </div>

      {/* Buttons fixed to the bottom */}
      <div className="button-container">
        <button onClick={handlePlayClick} className="game-play-button">Play</button>
        <button className="game-multiplayer-button">Multiplayer</button>
      </div>
    </div>
  );
};

export default GamePage;
