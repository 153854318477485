import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import LoadingPage from './pages/LoadingPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/game/:gameId" element={<GamePage />} />
        <Route path="/loading/:gameId" element={<LoadingPage />} /> {/* Loading route */}
      </Routes>
    </Router>
  );
}

export default App;
