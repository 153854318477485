// HomePage.js
import React from 'react';
import GameCard from '../components/GameCard';
import './HomePage.css';

const HomePage = () => {
  const games = [
    {
      id: 1,
      title: "Destroy Drops",
      genre: 'Simulation',
      gameplayImage: '/images/tobi-gameplay.jpg',
      iconImage: '/images/tobi-icon.jpg',
    },
    {
      id: 2,
      title: 'Rythm Run',
      genre: 'Adventure',
      gameplayImage: '/images/epic-gameplay.jpg',
      iconImage: '/images/epic-icon.jpg',
    },
    {
      id: 3,
      title: 'Mad Marbles',
      genre: 'Racing',
      gameplayImage: '/images/racing-gameplay.jpg',
      iconImage: '/images/racing-icon.jpg',
    },
    {
      id: 4,
      title: 'Trivia Chaser',
      genre: 'Trivia',
      gameplayImage: '/images/trivia-gameplay.jpg',
      iconImage: '/images/trivia-icon.jpg',
    },
  ];

  const username = `User${Math.floor(1000 + Math.random() * 9000)}`;
  const randomIconNumber = Math.floor(Math.random() * 4) + 1; // Generates a random number between 1 and 4
  const userIcon = `/images/user-icon${randomIconNumber}.jpg`;

  return (
    <div className="homepage">
      <div className="top-section">
        <div className="user-info">
          <img src={userIcon} alt="User Icon" className="user-icon" />
          <div className="user-text">
            <div className="username">{username}</div>
            <div className="save-progress" onClick={() => alert('Coming Soon!')}>
              Save my Progress
            </div>
          </div>
        </div>
      </div>

      <h1 className="games-title">Browse Games:</h1>

      <div className="game-card-grid">
        {games.map((game) => (
          <GameCard key={game.id} game={game} />
        ))}
      </div>
    </div>
  );
};

export default HomePage;
