// LoadingPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './LoadingPage.css';

const LoadingPage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [isAdCompleted, setIsAdCompleted] = useState(false);

  useEffect(() => {
    const randomLoading = () => {
      const increment = Math.floor(Math.random() * 15) + 5;
      setProgress((prev) => Math.min(prev + increment, 100));
    };

    const interval = setInterval(() => {
      if (progress < 100) {
        randomLoading();
      }
    }, Math.floor(Math.random() * 1500) + 500);

    const adTimer = setTimeout(() => setIsAdCompleted(true), 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(adTimer);
    };
  }, [progress]);

  const handleStartClick = async () => {
    try {
      const unityLoaderScript = document.createElement('script');
      unityLoaderScript.src = `/unity/DestroyDrops/Build/SinglePlayer 0.0.8V.loader.js`;
      unityLoaderScript.onload = () => {
        window.createUnityInstance(document.getElementById("unity-container"), {
          dataUrl: `/unity/DestroyDrops/Build/SinglePlayer 0.0.8V.data.unityweb`,
          frameworkUrl: `/unity/DestroyDrops/Build/SinglePlayer 0.0.8V.framework.js.unityweb`,
          codeUrl: `/unity/DestroyDrops/Build/SinglePlayer 0.0.8V.wasm.unityweb`,
          streamingAssetsUrl: `/unity/DestroyDrops/StreamingAssets`,
          companyName: "YourCompany",
          productName: "DestroyDrops",
          productVersion: "0.0.8",
        });
      };
      document.body.appendChild(unityLoaderScript);
    } catch (error) {
      console.error("Failed to load Unity loader:", error);
    }
  };

  const displayText = progress < 100 ? `${Math.ceil((100 - progress) / 20)} seconds left...` : 'Ready!';

  return (
    <div className="loading-page">
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}>
          <p className="time-left">{displayText}</p>
          {progress >= 100 && (
            <button onClick={handleStartClick} className="start-button">
              <span className="arrow-icon">→</span>
            </button>
          )}
        </div>
      </div>
      <div className="ad-container">
        <img src="/images/banner-ad.jpg" alt="Banner Ad" className="banner-ad" />
      </div>
      <div id="unity-container" className="unity-container"></div>
    </div>
  );
};

export default LoadingPage;
